var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('settings-button',{attrs:{"image":{
        src: require('@/assets/logout.svg'),
        alt: 'Logout icon'
      },"lable":"Ausloggen"},on:{"click":_vm.logout}}),_c('div',{staticClass:"options"},[_c('h1',[_vm._v("Anzeigeoptionen")]),_c('settings-toggle',{staticClass:"toggle",attrs:{"value":_vm.weekType,"label":"A/B-Woche anzeigen"},on:{"input":function($event){return _vm.save('content', {..._vm.contentOptions, week_type: $event})}}}),_c('settings-toggle',{staticClass:"toggle",attrs:{"value":_vm.additionalInfo,"label":"Informationen zum Tag anzeigen"},on:{"input":function($event){return _vm.save('content', {..._vm.contentOptions, additional_info: $event})}}})],1),_c('settings-button',{class:{delete: true, pressed: _vm.authorizing},attrs:{"image":{
      src: require('@/assets/delete_forever.svg'),
      alt: 'Delete forever icon'
    },"text-style":{color: 'white', 'font-weight': 'bold'},"lable":_vm.authorizing ? 'Warten auf Authentifizierung…' : 'Account löschen'},on:{"contextmenu":function($event){$event.preventDefault();}},nativeOn:{"mousedown":function($event){_vm.deletionStart = new Date()},"touchstart":function($event){_vm.deletionStart = new Date()},"mouseup":function($event){return _vm.deleteAccount.apply(null, arguments)},"touchend":function($event){return _vm.deleteAccount.apply(null, arguments)}}}),_c('error-message',{attrs:{"message":"Account konnte nicht gelöscht werden, versuche es später noch einmal"},model:{value:(_vm.deletionError),callback:function ($$v) {_vm.deletionError=$$v},expression:"deletionError"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }